import React from 'react';
import './HatenaBookmarkButton.css';

export default ({ url, title }) => (
  <div
    role="button"
    tabIndex="0"
    className="SocialMediaShareButton SocialMediaShareButton--hatena"
  >
    <a
      className="HatenaBookmarkButton"
      href={`http://b.hatena.ne.jp/add?mode=confirm&url=${url}&title=${title}`}
      target="_blank"
      rel="nofollow"
    />
  </div>
);
